import { ROUTE_PATH as ONBOARDIG_PATH } from '#app/routes/onboarding+/username'
import { Link } from '@remix-run/react'
import { buttonVariants } from '../ui/button'

export function MainSectionRight({
  imageUrl,
  title,
  description,
  buttonText,
}: {
  imageUrl?: string
  title?: string
  description?: string
  buttonText?: string
}) {
  return (
    <section>
      <div className="container mx-auto flex flex-col-reverse items-center justify-center gap-8 px-6 lg:flex-row lg:gap-x-10 lg:px-16">
        <div className="w-full max-w-lg lg:w-1/2">
          <h2 className="max-w-md text-pretty text-4xl font-bold tracking-tight text-primary sm:text-5xl">
            {title}
          </h2>
          <p className="my-6 max-w-md text-xl font-normal leading-8 text-primary sm:text-2xl">
            {description}
          </p>
          {buttonText && (
            <Link
              to={ONBOARDIG_PATH}
              className={`${buttonVariants({ size: 'lg' })} h-12 rounded-lg`}>
              {buttonText}
            </Link>
          )}
        </div>
        <div className="w-full max-w-md lg:w-1/2">
          <img
            src={imageUrl}
            alt="Immotree app screenshot"
            className="mx-auto w-full max-w-full object-contain"
          />
        </div>
      </div>
    </section>
  )
}
