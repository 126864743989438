import type { MetaFunction, LoaderFunctionArgs, HeadersFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import { json } from '@remix-run/node'
import { authenticator } from '#app/modules/auth/auth.server'
import { siteConfig } from '#app/utils/constants/brand'
import { Hero } from '#app/components/hero'
import { MainNavigation } from '#app/components/main-navigation.js'
import { AgentsBanner } from '#app/components/landing-page/agents-banner.js'
import { MainSectionLeft } from '#app/components/landing-page/main-section-left.js'
import { MainSectionRight } from '#app/components/landing-page/main-section-right.js'
import { Benefits } from '#app/components/landing-page/benefits.js'
import { ComparisonTable } from '#app/components/comparison-table.js'
import { CTAFooter } from '#app/components/landing-page/cta-footer.js'
import { MainFooter } from '#app/components/landing-page/main-footer.js'
import { useTranslation } from 'react-i18next'
import { Pricing } from '#app/components/pricing.js'
import UsernamesSection from '#app/components/landing-page/usernames-section.js'

export const meta: MetaFunction = () => {
  const title = siteConfig.siteTitle
  const description =
    'Create a free link in bio microsite to acquire more real estate buyers and sellers'
  const image =
    'https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/ihpu7mlhilibilezhbpj'
  const url = 'https://immotr.ee'

  return [
    { title },
    { name: 'description', content: description },
    // Open Graph
    { property: 'og:site_name', content: 'Immotree' },
    { property: 'og:url', content: url },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
    // Twitter
    { name: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:domain', content: 'immotr.ee' },
    { property: 'twitter:url', content: url },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: image },
  ]
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return { 'Cache-Control': loaderHeaders.get('Cache-Control') ?? '' }
}

export async function loader({ request }: LoaderFunctionArgs) {
  const sessionUser = await authenticator.isAuthenticated(request)
  return json(
    { user: sessionUser },
    { headers: { 'Cache-Control': 'public, max-age=60' } },
  )
}
export default function Index() {
  const { user } = useLoaderData<typeof loader>()
  const { t } = useTranslation()
  return (
    <div className="relative flex h-full w-full flex-col bg-card">
      <MainNavigation isAuthenticated={!!user} />
      <Hero />
      <div className="mt-14"></div>
      <AgentsBanner />
      <div className="mt-14"></div>
      <MainSectionRight
        imageUrl="https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/wmpzqqkpmbaq67ec7klt"
        title={t('social_media_feature_title')}
        description={t('social_media_feature_description')}
        buttonText={t('get_started_cta')}
      />
      <div className="mt-14"></div>
      <MainSectionLeft
        imageUrl="https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/dageaxahy1xvumfcemrs"
        title={t('conversion_feature_title')}
        description={t('conversion_feature_description')}
        buttonText={t('get_started_cta')}
      />

      <div className="mt-14"></div>
      <MainSectionRight
        imageUrl="https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/lo1mucbgbwg74m1gpq6f"
        title={t('listing_showcase_feature_title')}
        description={t('listing_showcase_feature_description')}
        buttonText={t('get_started_cta')}
      />
      <div className="mt-14"></div>
      <MainSectionLeft
        imageUrl="https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/dykb5rxojr5nekfbxxlz"
        title={t('automation_feature_title')}
        description={t('automation_feature_description')}
        buttonText={t('get_started_cta')}
      />
      <div className="mt-14"></div>
      <Benefits />
      <div className="mt-14"></div>
      <ComparisonTable />
      <div className="mt-14"></div>
      <Pricing />
      {/* <div className="mt-14"></div>
       <Testimonial /> */}
      <div className="mt-14"></div>
      <UsernamesSection />
      <div className="mt-14"></div>
      <CTAFooter />
      <div className="mt-14"></div>
      <MainFooter />
    </div>
  )
}
