import { Button } from '../ui/button'
import { ROUTE_PATH as ONBOARDIG_PATH } from '#app/routes/onboarding+/username'
import { useTranslation } from 'react-i18next'

export function CTAFooter() {
  const { t } = useTranslation()
  return (
    <section className="mt-16 bg-[#6A94E6]">
      <div className="container mt-16 flex flex-col items-center justify-center gap-14 py-44">
        <h2 className="text-7xl font-bold text-primary-foreground">
          {t('start_your_journey_now')}
        </h2>
        <div className="mt-8 flex flex-col gap-4 sm:flex-row sm:items-center">
          <form
            method="GET"
            id="username-form"
            action={ONBOARDIG_PATH}
            className="flex h-12 w-full max-w-xs items-center justify-start rounded-lg border">
            <span className="px-2 text-xs text-primary">immotr.ee/</span>
            <input
              type="text"
              name="username"
              className="h-full w-full bg-transparent text-xs text-primary placeholder-primary/70 focus:outline-none"
              placeholder={t('username_placeholder')}
            />
          </form>
          <Button
            type="submit"
            form="username-form"
            className="h-12 w-full rounded-lg bg-[#091834] sm:w-auto">
            {t('claim_username_cta')}
          </Button>
        </div>
      </div>
    </section>
  )
}
