import { useTranslation } from 'react-i18next'

export function Benefits() {
  const { t } = useTranslation()
  return (
    <section className="bg-[#EDF1F8]">
      <div className="just container flex flex-col items-center justify-center gap-10 py-20">
        <h2 className="text-center text-5xl font-bold text-primary">
          {t('we_help_you_grow_your_business')}
        </h2>
        <div className="lg:flex-shrink-0 lg:flex-grow">
          <img
            src="https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/eqmnj4ls95zqsp3qm6q9"
            alt={t('proplinkio_app_screenshot')}
            className="mx-auto max-w-full"
          />
        </div>
        <div className="grid max-w-lg lg:max-w-5xl lg:grid-cols-3">
          <div className="p-2">
            <span>
              <svg
                width="64"
                height="65"
                viewBox="0 0 64 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 16.7705C0 7.93395 7.16344 0.770508 16 0.770508H48C56.8366 0.770508 64 7.93395 64 16.7705V48.7705C64 57.6071 56.8366 64.7705 48 64.7705H16C7.16344 64.7705 0 57.6071 0 48.7705V16.7705Z"
                  fill="#6A94E6"
                />
                <path
                  d="M35.4284 40.1987C34.0698 40.1999 32.7371 39.8274 31.576 39.1219C30.415 38.4164 29.4704 37.4051 28.8456 36.1987H35.4284L36.5713 33.9129H28.0913C28.0341 33.5358 27.9999 33.1587 27.9999 32.7701C27.9999 32.3815 28.0341 32.0044 28.0913 31.6272H35.4284L36.5713 29.3415H28.8456C29.4714 28.1359 30.4162 27.1253 31.577 26.42C32.7379 25.7146 34.0701 25.3416 35.4284 25.3415C37.2684 25.3415 38.9599 26.0158 40.2627 27.1358L42.2856 25.1129C40.4035 23.4191 37.9605 22.4826 35.4284 22.4844C30.9484 22.4844 27.1541 25.3529 25.737 29.3415H21.7141L20.5713 31.6272H25.2113C25.1192 32.3864 25.1192 33.1538 25.2113 33.9129H21.7141L20.5713 36.1987H25.737C27.1541 40.1872 30.9484 43.0558 35.4284 43.0558C38.0684 43.0558 40.4684 42.0615 42.2856 40.4272L40.2513 38.4044C38.9599 39.5244 37.2799 40.1987 35.4284 40.1987Z"
                  fill="white"
                />
              </svg>
            </span>
            <p className="my-8 text-lg font-normal leading-7 text-primary">
              <span className="font-bold">{t('tailored_for_real_estate_agents')}</span>
              <p>{t('tailored_description')}</p>
            </p>
          </div>
          <div className="p-2">
            <span>
              <svg
                width="64"
                height="65"
                viewBox="0 0 64 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 16.7705C0 7.93395 7.16344 0.770508 16 0.770508H48C56.8366 0.770508 64 7.93395 64 16.7705V48.7705C64 57.6071 56.8366 64.7705 48 64.7705H16C7.16344 64.7705 0 57.6071 0 48.7705V16.7705Z"
                  fill="#6A94E6"
                />
                <path
                  d="M41.1428 22.4854H35.4285C34.7973 22.4854 34.2856 22.997 34.2856 23.6282V29.3425C34.2856 29.9737 34.7973 30.4854 35.4285 30.4854H41.1428C41.774 30.4854 42.2856 29.9737 42.2856 29.3425V23.6282C42.2856 22.997 41.774 22.4854 41.1428 22.4854Z"
                  stroke="white"
                  strokeWidth="2.28571"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M29.7144 43.0566V28.1995C29.7144 27.8964 29.5939 27.6057 29.3796 27.3914C29.1653 27.177 28.8746 27.0566 28.5715 27.0566H22.8572C22.5541 27.0566 22.2634 27.177 22.0491 27.3914C21.8348 27.6057 21.7144 27.8964 21.7144 28.1995V41.9138C21.7144 42.2169 21.8348 42.5076 22.0491 42.7219C22.2634 42.9362 22.5541 43.0566 22.8572 43.0566H36.5715C36.8746 43.0566 37.1653 42.9362 37.3796 42.7219C37.5939 42.5076 37.7144 42.2169 37.7144 41.9138V36.1995C37.7144 35.8964 37.5939 35.6057 37.3796 35.3914C37.1653 35.177 36.8746 35.0566 36.5715 35.0566H21.7144"
                  stroke="white"
                  strokeWidth="2.28571"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <p className="my-8 text-lg font-normal leading-7 text-primary">
              <span className="font-bold">{t('quick_and_easy_setup')}</span>
              <p>{t('quick_setup_description')}</p>
            </p>
          </div>
          <div className="p-2">
            <span>
              <svg
                width="64"
                height="65"
                viewBox="0 0 64 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 16.7705C0 7.93395 7.16344 0.770508 16 0.770508H48C56.8366 0.770508 64 7.93395 64 16.7705V48.7705C64 57.6071 56.8366 64.7705 48 64.7705H16C7.16344 64.7705 0 57.6071 0 48.7705V16.7705Z"
                  fill="#6A94E6"
                />
                <path
                  d="M41.1428 22.4854H35.4285C34.7973 22.4854 34.2856 22.997 34.2856 23.6282V29.3425C34.2856 29.9737 34.7973 30.4854 35.4285 30.4854H41.1428C41.774 30.4854 42.2856 29.9737 42.2856 29.3425V23.6282C42.2856 22.997 41.774 22.4854 41.1428 22.4854Z"
                  stroke="white"
                  strokeWidth="2.28571"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M29.7144 43.0566V28.1995C29.7144 27.8964 29.5939 27.6057 29.3796 27.3914C29.1653 27.177 28.8746 27.0566 28.5715 27.0566H22.8572C22.5541 27.0566 22.2634 27.177 22.0491 27.3914C21.8348 27.6057 21.7144 27.8964 21.7144 28.1995V41.9138C21.7144 42.2169 21.8348 42.5076 22.0491 42.7219C22.2634 42.9362 22.5541 43.0566 22.8572 43.0566H36.5715C36.8746 43.0566 37.1653 42.9362 37.3796 42.7219C37.5939 42.5076 37.7144 42.2169 37.7144 41.9138V36.1995C37.7144 35.8964 37.5939 35.6057 37.3796 35.3914C37.1653 35.177 36.8746 35.0566 36.5715 35.0566H21.7144"
                  stroke="white"
                  strokeWidth="2.28571"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <p className="my-8 text-lg font-normal leading-7 text-primary">
              <span className="font-bold">{t('comprehensive')}</span>
              <p>{t('comprehensive_description')}</p>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
