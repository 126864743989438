import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'

import { ROUTE_PATH as ONBOARDIG_PATH } from '#app/routes/onboarding+/username'
import { buttonVariants } from '../ui/button'
export default function UsernamesSection() {
  const { t } = useTranslation()
  return (
    <section>
      <div className="container max-w-2xl lg:max-w-5xl">
        <div className="mb-9 space-y-4 text-center">
          <h2 className="text-5xl font-bold">{t('reserve_your_name')}</h2>
          <p className="text-2xl text-[#091834]">{t('reserve_your_name_description')}</p>
          <Link
            to={ONBOARDIG_PATH}
            className={`${buttonVariants({ size: 'lg' })} h-12 rounded-lg`}>
            {t('reserve_your_name_cta')}
          </Link>
        </div>
        <img
          src="https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/u2svr8j78cy6jeln6cwk"
          alt="Immotree app screenshot"
          className="mx-auto w-full max-w-full object-contain"
        />
      </div>
    </section>
  )
}
