import { useTranslation } from 'react-i18next'

export function AgentsBanner() {
  const { t } = useTranslation()
  const agentImages = [
    'https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/jwf1qsxmfwmun1umyfs7',
    'https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/nmdgozlswa2gfwho3c1e',
    'https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/mikramtisthginayuvj9',
    'https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/yluds9uzocn95dkbvphn',
    'https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/m23h4z9iunr9p6hvxnpk',
    'https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/r6cdenjvwuf3tuhznlxp',
  ]
  return (
    <section className="mt-14">
      <div className="container max-w-lg items-center justify-center gap-x-14 px-20 pb-14 lg:flex lg:max-w-7xl">
        <div className="flex -space-x-4 overflow-hidden">
          {agentImages.map((src, index) => (
            <img
              key={index}
              className="inline-block h-20 w-20 rounded-full border-border"
              src={src}
              alt={`Agent ${index + 1}`}
            />
          ))}
        </div>
        <h2 className="text-3xl font-bold leading-10">
          {t('join_100_real_estate_agents_across_germany')}
        </h2>
      </div>
    </section>
  )
}
