import { ROUTE_PATH as ONBOARDIG_PATH } from '#app/routes/onboarding+/username'
import { Link } from '@remix-run/react'
import { buttonVariants } from '../ui/button'
export function MainSectionLeft({
  imageUrl,
  title,
  description,
  buttonText,
}: {
  imageUrl?: string
  title?: string
  description?: string
  buttonText?: string
}) {
  return (
    <section>
      <div className="px-6 lg:px-16 container lg:flex lg:items-center lg:justify-center">
        <div className="mx-auto mt-16 w-full lg:w-1/2">
          <img src={imageUrl} alt="immotree app screenshot" className="mx-auto" />
        </div>
        <div className="mx-auto w-full max-w-lg lg:w-1/2">
          <h2 className="mt-10 max-w-md text-pretty text-5xl font-bold tracking-tight text-primary">
            {title}
          </h2>
          <p className="my-8 max-w-md text-2xl font-normal leading-8 text-primary">
            {description}
          </p>
          {buttonText ? (
            <Link
              to={ONBOARDIG_PATH}
              className={`${buttonVariants({ size: 'lg' })} h-14 rounded-2xl`}>
              {buttonText}
            </Link>
          ) : null}
        </div>
      </div>
    </section>
  )
}
