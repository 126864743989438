import { ROUTE_PATH as ONBOARDIG_PATH } from '#app/routes/onboarding+/username'
import { useTranslation } from 'react-i18next'
import { Button } from './ui/button'

export function Hero() {
  const { t } = useTranslation()
  return (
    <section className="bg-background p-4">
      <div className="container mx-auto flex flex-col-reverse items-center justify-center gap-8 lg:flex-row lg:items-center">
        <div className="w-full max-w-xl lg:w-1/3">
          <h1 className="text-4xl font-bold tracking-tight text-primary sm:text-5xl lg:text-6xl">
            {t('hero_title')}
          </h1>
          <p className="mt-4 text-xl font-normal leading-8 text-primary sm:text-2xl">
            {t('hero_description')}
          </p>
          <div className="mt-8 flex flex-col gap-4 sm:flex-row sm:items-center">
            <form
              method="GET"
              id="username-form"
              action={ONBOARDIG_PATH}
              className="flex h-12 w-full max-w-xs items-center justify-start rounded-lg border">
              <span className="px-2 text-xs text-primary">immotr.ee/</span>
              <input
                type="text"
                name="username"
                className="h-full w-full bg-transparent text-xs text-primary focus:outline-none"
                placeholder="yourusername"
              />
            </form>
            <Button
              type="submit"
              form="username-form"
              className="h-12 w-full rounded-lg bg-[#091834] sm:w-auto">
              {t('claim_username_cta')}
            </Button>
          </div>
        </div>
        <div className="w-full max-w-md lg:max-w-[640px] lg:w-2/3">
          <img
            src="https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/n4pzgvkxp0jgbwjxbcge"
            alt="Immotree app illustration"
            className="mx-auto w-full drop-shadow-xl"
          />
        </div>
      </div>
    </section>
  )
}
