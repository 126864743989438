import { Link } from '@remix-run/react'
import { Logo } from '../logo'
import { ROUTE_PATH as ONBOARDIG_PATH } from '#app/routes/onboarding+/username'

export function MainFooter() {
  return (
    <footer className="bg-background py-6">
      <div className="container lg:flex lg:items-end lg:gap-x-10 lg:px-40 lg:py-14">
        <div className=" flex flex-col items-center gap-y-2 h-full lg:flex lg:flex-col lg:justify-between lg:gap-y-52">
          <Logo />
          <p className="text-xs">&copy; 2024 immotr.ee,. All rights reserved.</p>
        </div>
        <nav className="flex flex-col text-lg items-center lg:items-start mt-4 lg:mt-0 sm:ml-auto sm:gap-6">
          <Link to="#" className="text-base underline-offset-4 hover:underline">
            About
          </Link>
          <Link to="#" className="text-base underline-offset-4 hover:underline">
            Features
          </Link>
          <Link
            to={ONBOARDIG_PATH}
            className="text-base underline-offset-4 hover:underline">
            Reserve your username
          </Link>
        </nav>
      </div>
    </footer>
  )
}
